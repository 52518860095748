import axios from "axios";
const request = axios.create({
  // 测试
  baseURL: 'https://tapic.yangtao.sing18.com'
  // 正式
  // baseURL: 'https://apic.yangtao.sing18.com'
});
// 添加请求拦截器
request.interceptors.request.use(function (config) {
  config.headers["Content-type"] = config.type;
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});



export default request