import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//npm install axios -S
import axios from "axios";
Vue.prototype.$axios = axios;
//npm install vue-axios -S
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
Vue.config.productionTip = false;
// axios.defaults.baseURL = "http://tapic.yangtao.sing18.com:3443";
// // 添加请求拦截器
// axios.interceptors.request.use(function (config) {
//   // 在发送请求之前做些什么
//   config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
//   return config;
// }, function (error) {
//   // 对请求错误做些什么
//   return Promise.reject(error);
// });

// // 添加响应拦截器
// axios.interceptors.response.use(function (response) {
//   return response;
// }, function (error) {
//   // 超出 2xx 范围的状态码都会触发该函数。
//   // 对响应错误做点什么
//   return Promise.reject(error);
// });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
