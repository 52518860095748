<template>
    <div class="main">
        <button @click="download">立即下载</button>
        <div v-if="isMasking" class="Masking">
            <div class="Masking-item">
                <img class="img" src="../assets/jt (1).png" alt="">
                <div class="text">
                    <div class="text-item">① 请点击右上角</div>
                    <div class="text-item ">② 在浏览器中打开 <img class="img1" src="../assets/llq.png" alt=""> </div>
                    <div>or</div>
                    <!-- <div class="text-item">① 请点击右上角</div> -->
                    <div class="text-item ">① 复制链接 </div>
                    <div class="text-item">② 用自带浏览器打开 <img class="img1" src="../assets/llq.png" alt=""></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isMasking: false,

        }
    },
    created() {
    },
    methods: {
        download() {
            var u = navigator.userAgent;
            console.log(u);
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (navigator.userAgent.indexOf('MicroMessenger') > -1) {
                this.isMasking = true
            } else if (isAndroid) {
                location.href = 'https://sj.qq.com/'
            } else if (isiOS) {
                location.href = 'https://apps.apple.com/cn/app/%E4%B9%90%E7%9B%8A%E6%92%AD/id1562606609'
            } else {
                alert('暂不支持浏览器 请复制链接到手机自带浏览器中打开')
            }
        }

    }
}
</script>

<style lang="scss">

</style>
