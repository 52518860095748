import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DownLoadView from '../views/DownloadView.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/DownLoad',
    name: 'DownLoad',
    component: DownLoadView
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  //   redirect:"/about/userView",
  //   children:[
  //     {path:'userView', component: userView},
  //     {path:'roleView', component: roleView},
  //     {path:'menuView', component: menuView},

  //   ],
  //   // redirect:"userView"
  // }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
