<template>
  <div class="main">
    <div class="container" :style="background_img">
      <div class="logo">
        <img class="logo" :src="logo_img" alt />
      </div>
      <div class="biao">
        <img class="biao" :src="biao_img" alt />
      </div>
      <div :class="color ? 'registeredb' : 'registeredw'" class="registered">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="auto"
          class="demo-ruleForm"
        >
          <el-form-item v-if="isDownload" prop="telephone">
            <el-input placeholder="请输入手机号" maxlength="11" v-model="ruleForm.telephone" class="inpt"></el-input>
          </el-form-item>
          <el-form-item v-if="isDownload" prop="code">
            <el-input placeholder="请输入验证码" v-model="ruleForm.code" class="inpt">
              <template slot="append">
                <div @click="getCodeRequest">{{ CaptchaTitle }}</div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item v-if="isDownload" prop="telephone">
            <el-input placeholder="请输入邀请码" v-model="ruleForm.i_code" class="inpt"></el-input>
          </el-form-item>
          <div class="succsee" v-if="!isDownload">
            <div class="success-item">
              <img src="../assets/success.png" alt />
            </div>
            <!-- <div class="success-item">恭喜！注册完成</div> -->
            <div class="success-item">赶紧下载吧~</div>
          </div>
          <el-button
            class="login"
            v-if="isDownload"
            type="primary"
            @click="submitForm('ruleForm')"
          >立即注册</el-button>
          <el-button class="login" v-else type="primary" @click="jump()">立即下载</el-button>
          <div class="agreement" v-if="isDownload" :style="color ? 'color:#fff' : 'color:#000'">
            <input style="margin-right:5px" type="checkbox" v-model="checked" />我已阅读并同意
            <a :style="color ? 'color:#0041a3' : 'color:#066df3'">《央淘隐私政策》</a>
          </div>
        </el-form>
      </div>
    </div>

    <div v-if="isMasking" class="Masking" @touchmove.prevent>
      <div class="Masking-item">
        <img class="img" src="../assets/jt (1).png" alt />
        <div class="text">
          <div class="text-item">注册/下载央淘</div>
          <div class="text-item2">
            <img class="img1" src="../assets/llq.png" alt />点击右上角选择浏览器打开
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import rgbaster from "rgbaster";
import request from "@/utils/request";
// import qs from 'qs';
export default {
  data() {
    // 验证手机号的规则
    var checkMobile = (rules, value, cb) => {
      const regMobile = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (regMobile.test(value)) {
        return cb();
      }
      cb(new Error("请输入正确的手机号"));
    };
    return {
      logo_img: require("../assets/logo.png"),
      biao_img: require("../assets/biao.png"),
      background_img: {
        backgroundImage: `url(${require("../assets/bottom.jpg")})`
      },
      ruleForm: {
        telephone: "",
        code: "",
        i_code: ""
      },
      checked: false,
      CaptchaTitle: "获取验证码",
      CaptchaTime: 60,
      CaptchaClick: false,
      isDownload: false,
      isMasking: false,
      rules: {
        telephone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { required: true, validator: checkMobile, trigger: "blur" }
        ],
        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }]
      },
      imgSrc: require("../assets/bottom.jpg"),
      color: true
    };
  },
  created() {
    if (navigator.userAgent.indexOf("MicroMessenger") > -1) {
      this.isMasking = true;
    }
    this.loadImage();
  },
  components: {},
  methods: {
    loadImage() {
      let result = rgbaster(this.imgSrc, {
        scale: 0.6
      });
      result.then(res => {
        let imgColor = res[0].color;
        var arr = imgColor
          .replace("rgb(", "")
          .replace(")", "")
          .split(",")
          .map(Number);
        var $grayLevel = arr[0] * 0.299 + arr[1] * 0.587 + arr[2] * 0.114;
        if ($grayLevel >= 192) {
          return (this.color = false);
        } else {
          return (this.color = true);
        }
      });
    },

    submitForm(formName) {
      if (!this.checked)
        return this.$message({
          message: "请先同意央淘隐私协议",
          center: true
        });
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.login();
        } else {
          return false;
        }
      });
    },
    jump() {
      // this.$router.push({ path: "/DownLoad" });
      var u = navigator.userAgent;
      console.log(u);
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (navigator.userAgent.indexOf("MicroMessenger") > -1) {
        this.isMasking = true;
      } else if (isAndroid) {
        location.href = "https://sj.qq.com/appdetail/com.yangtao.shopping";
      } else if (isiOS) {
        location.href =
          "https://apps.apple.com/cn/app/%E5%A4%AE%E6%B7%98/id6446424789";
      } else {
        alert("暂不支持浏览器 请复制链接到手机自带浏览器中打开");
      }
    },

    async getCodeRequest() {
      if (this.CaptchaClick) return;
      this.CaptchaClick = true;
      let that = this;
      const res = await request({
        url: "/api/v1/auth/loginCode",
        method: "post",
        data: { telephone: that.ruleForm.telephone },
        type: "application/json"
      });
      that.CaptchaTitle = that.CaptchaTime + "s";
      let timer = setInterval(function() {
        if (that.CaptchaTime > 0) {
          that.CaptchaTime--;
          that.CaptchaTitle = that.CaptchaTime + "s";
        } else {
          that.CaptchaTitle = "获取验证码";
          that.CaptchaTime = 60;
          that.CaptchaClick = false;
          clearInterval(timer);
        }
      }, 1000);
      if (res.data.code == 200) {
        this.$message({
          message: "验证码获取成功",
          type: "success",
          center: true
        });
      } else if (res.data.code == 400) {
        this.$message({
          message: "手机号不能为空",
          center: true
        });
        that.CaptchaTitle = "获取验证码";
        that.CaptchaTime = 60;
        that.CaptchaClick = false;
        clearInterval(timer);
      }
    },
    async login() {
      // this.ruleForm.member = this.$route.query.member || ''
      // this.ruleForm.t = this.$route.query.t || 0
      let url = window.location.href;
      let obj = {};
      if (url.indexOf("?") !== -1) {
        let startIndex = url.indexOf("?") + 1;
        let str = url.substr(startIndex);
        console.log(str);
        let strs = [];
        if (str.indexOf("#") !== -1) {
          let str1 = str.slice(0, str.length - 2);
          strs = str1.split("&");
        } else {
          strs = str.split("&");
        }
        for (let i = 0; i < strs.length; i++) {
          obj[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
        obj.telephone = this.ruleForm.telephone;
        obj.code = this.ruleForm.code;
        obj.i_code = this.ruleForm.i_code;
        const res = await request({
          url: "/api/v1/auth/login",
          method: "post",
          data: obj,
          type: "application/json"
        });
        console.log(res)
        if (res.data.code == 200) {
          this.isJump = false;
          this.isDownload = false;
          return this.$message({
            message: "注册成功",
            type: "success",
            center: true
          });
        } else {
          console.log(res)
          this.$message({
            message: res.err_msg,
            type: "error",
            center: true
          });
        }
      } else {
        const res = await request({
          url: "/api/v1/auth/login",
          method: "post",
          data: this.ruleForm,
          type: "application/json"
        });
        if (res.data.code == 200) {
          this.isJump = false;
          this.isDownload = false;
          return this.$message({
            message: "注册成功",
            type: "success",
            center: true
          });
        } else {
          this.$message({
            message: res.data.err_msg,
            type: "error",
            center: true
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.main {
  width: 100%;
  height: 100%;
}

.container {
  max-width: 800px;
  margin: auto;
  text-align: center;
  box-sizing: border-box;
  padding: 50px 20px;
  min-height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.logo {
  img {
    width: auto;
    height: 10vh;
    border-radius: 20px;
  }
}

.biao {
  img {
    width: 80%;
    height: auto;
  }
}

.registered {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 20px;
}

.registeredw {
  box-shadow: 0 0 3px 3px rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.2);
}

.registeredb {
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.flushed {
  position: absolute;
  right: 10px;
  top: 10px;
}

.demo-ruleForm {
  box-sizing: border-box;
}

.el-form-item__content {
  line-height: 0 !important;
}

.inpt {
  overflow: hidden;
  border: 0.5px solid rgb(223, 223, 223);
  border-radius: 30px !important;

  input {
    border: 0 !important;
    outline: none !important;
  }

  .el-input-group__append {
    border: 0;
    background-color: white;
    padding: 0;

    div {
      background-color: #c72e29;
      padding: 5px;
      border-radius: 40px;
      font-size: 12px !important;
      color: white;
      box-sizing: border-box;
      margin: 5px;
    }
  }
}

.el-form-item__error {
  color: #808080 !important;
}

.succsee {
  .success-item {
    color: white;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;

    img {
      width: 80px;
      height: 80px;
    }
  }
}

.login {
  width: 100%;
  height: 40px;
  border: 0 !important;
  border-radius: 30px !important;
  background-color: white !important;
  color: #c72e29 !important;
}

.agreement {
  display: flex;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 5px;
  align-items: center;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.Masking {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;

  .Masking-item {
    position: absolute;
    right: 0px;
    top: 10px;
    padding-top: 100px;

    .img {
      height: 100px;
      width: 100px;
      position: absolute;
      top: 0;
      right: 0;
    }

    .text {
      color: white;
      font-size: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding-right: 20px;
      letter-spacing: 0.01cm;

      .text-item {
        padding: 2px 0px;
        font-weight: bold;
      }

      .text-item2 {
        font-size: 16px;
        display: flex;
        align-items: center;

        .img1 {
          height: 20px;
          width: 20px;
          padding: 5px;
        }
      }
    }
  }
}
</style>
